@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap');

input#myInput::-webkit-calendar-picker-indicator {
  display: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-container input {
  font-size: 16px;
  font-family: 'Open Sans';
  background: none;
  padding: 0;
  border: 0;
  outline: 0;
  width: 100% !important;
}

.react-datepicker__month {
  min-height: 15em;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #3e4e63;
}

.react-datepicker__month-container {
  font-family: 'Open Sans';
}

/* trying to fix cut off time */
.react-datepicker__input-container input {
  width: auto;
}

table {
  font-family: 'open sans', sans-serif;
  border: 1px solid #ccc;
  padding: 0;
  border-collapse: collapse;
}
th,
td {
  padding: 10px 5px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
th {
  font-weight: bold;
  background: rgb(220, 240, 242);
}
tr:nth-child(even) td {
  background: #f1f1f1;
}

@media (max-width: 480px) {
  table {
    font-size: 13px;
  }
  th,
  td {
    padding: 7px 0;
  }
}

.react-grid-item.react-grid-placeholder {
  background: rgba(62, 172, 190, 0.1) !important;
  border: 2px dashed #ccc;
  opacity: 1 !important;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-datepicker-wrapper {
  width: 100%;
}
